import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { Col } from 'react-flexbox-grid';

import { ContactFormStyle } from './styles';
import ApiService from '../../services/api';

const STATUS = {
  NONE: 0,
  PROCESSING: 1,
  ERROR: 2,
  SUCCESS: 3,
};

const ContactForm: React.FC = () => {
  const inputNameRef = useRef<HTMLInputElement>(null);
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputMessageRef = useRef<HTMLTextAreaElement>(null);

  const [status, setSatus] = useState(STATUS.NONE);

  const clearError = () => {
    setSatus(STATUS.NONE);
  };

  const handleSubmit = useCallback(async (evt: FormEvent) => {
    evt.preventDefault();

    const name = inputNameRef.current?.value;
    const email = inputEmailRef.current?.value;
    const message = inputMessageRef.current?.value;

    if (!name || !email || !message) {
      setSatus(STATUS.ERROR);
      return;
    }

    setSatus(STATUS.PROCESSING);

    try {
      await ApiService.sendEmail(name, email, message);

      setSatus(STATUS.SUCCESS);

      if (inputNameRef.current) inputNameRef.current.value = '';
      if (inputEmailRef.current) inputEmailRef.current.value = '';
      if (inputMessageRef.current) inputMessageRef.current.value = '';
    } catch (err) {
      setSatus(STATUS.ERROR);
    }
  }, []);

  return (
    <ContactFormStyle>
      <div className="title">
        <h2>
          Ask a question or report a problem
          <br /> Just send us an email
        </h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group colum-row form-row">
          <Col className="form-col" md={6}>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Name"
              required
              minLength={8}
              maxLength={50}
              onFocus={clearError}
              ref={inputNameRef}
            />
          </Col>
          <Col className="form-col" md={6}>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required
              maxLength={50}
              onFocus={clearError}
              ref={inputEmailRef}
            />
          </Col>
        </div>
        <div className="form-group form-row">
          <div className="form-col">
            <textarea
              id="message"
              name="message"
              cols={30}
              rows={5}
              className="form-control message"
              placeholder="Message"
              required
              minLength={16}
              maxLength={4096}
              onFocus={clearError}
              ref={inputMessageRef}
            />
          </div>
        </div>
        <div className="form-group form-row">
          <div className="form-col">
            <button
              id="submit"
              className="default-btn submit-button"
              type="submit"
              disabled={status === STATUS.PROCESSING}
            >
              {status === STATUS.PROCESSING ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </div>
        <div role="alert">
          {status === STATUS.SUCCESS
            ? 'Thank You! Your message has been sent.'
            : ''}
          <span className="error-message">
            {status === STATUS.ERROR
              ? "There's something wrong! Try again."
              : ''}
          </span>
        </div>
      </form>
    </ContactFormStyle>
  );
};

export default ContactForm;
