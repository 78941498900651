import styled from '@emotion/styled';

import { Breakpoints, Colors } from '../../styles/theme';

export const ContactStyle = styled.section`
  padding: 80px 20px;
  background-color: ${Colors.black};
`;

export const ContactFormStyle = styled.div`
  .title {
    margin-bottom: 30px;

    h2 {
      @media (max-width: ${Breakpoints.xs}px) {
        font-size: 20px;
      }
    }
  }

  form {
    .form-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .form-control {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      border: none;
      border-bottom: 1px solid ${Colors.blackTertiary};
      border-radius: 0;
      height: 45px;
      background-color: transparent;
      color: ${Colors.white};
      box-sizing: border-box;
      outline: none;
    }

    .form-col {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }

    textarea.form-control {
      height: 120px;
    }

    .error-message {
      color: ${Colors.red};
    }
  }
`;
