import React from 'react';
import { Global } from '@emotion/core';

import GlobalStyle from './global';

const Styles: React.FC = () => {
  return (
    <React.StrictMode>
      <Global styles={GlobalStyle} />
    </React.StrictMode>
  );
};

export default Styles;
