import {
  faCarCrash,
  faGlobeAmericas,
  faFillDrip,
  faMedal,
  faMusic,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';

export default [
  {
    name: 'Road Warrior',
    resume:
      'Road Warrior is set in a post-apocalyptic world where drivers have customized cars armed with arsenals of weapons to give each racer a competitive advantage.\r\n\r\nThe goal is to win the race by successfully maneuvering the racetrack with numerous stunts, avoid being blown up, use your weapons to get ahead, and win the race.\r\n\r\nPlayers get to unlock and race customizable cars that can be equipped with deadly weapons and blow up their competitors.',
    store: {
      appStore: 'https://apps.apple.com/app/road-warrior-racing/id1551501412',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.rustyharpy.roadwarrior',
    },
    logo: './images/games/road-warrior/logo.png',
    images: [
      './images/games/road-warrior/road-warrior-1.jpg',
      './images/games/road-warrior/road-warrior-2.jpg',
      './images/games/road-warrior/road-warrior-3.jpg',
      './images/games/road-warrior/road-warrior-4.jpg',
    ],
    specifications: [
      {
        title: 'Dangerous Races',
        text:
          'Unlock and collect new, powerful Cars from sedans to massive trucks - each with a exclusive weapon and special ability.',
        icon: faCarCrash,
      },
      {
        title: 'Multiplayer',
        text:
          'Play battle races against people all over the world in a real-time multiplayer game.',
        icon: faGlobeAmericas,
      },
      {
        title: 'Customization',
        text:
          'Upgrade and customize your car with items and skins to proudly showcase to your opponents.',
        icon: faFillDrip,
      },
      {
        title: 'Ranking',
        text:
          'Climb to the top of the leaderboards in global and local rankings.',
        icon: faMedal,
      },
      {
        title: 'Sound',
        text:
          "Amazing rock'n'roll soundtrack and an over-the-top announcer on caffeine.",
        icon: faMusic,
      },
      {
        title: 'Mobile',
        text: 'Play for free on Google Play and Apple Store.',
        icon: faMobileAlt,
      },
    ],
  },
];
