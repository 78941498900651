import axios from 'axios';

class APIService {
  private apiUrl: string;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL as string;
  }

  status = async (): Promise<void> => {
    await axios.get(`${this.apiUrl}/`);
  };

  sendEmail = async (
    name: string,
    email: string,
    message: string
  ): Promise<void> => {
    await axios.post(`${this.apiUrl}/contacts`, {
      name,
      email,
      message,
    });
  };
}

export default new APIService();
