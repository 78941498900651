import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Footer from '../components/footer';

import ApiService from '../services/api';

import Home from './home';
import RoadWarriorPrivacy from './roadwarrior/privacy';

import { Routes } from '../router';

const App: React.FC = () => {
  useEffect(() => {
    ApiService.status();
  }, []);

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path={Routes.home} component={Home} />
          <Route
            exact
            path={Routes.roadWarriorPrivacy}
            component={RoadWarriorPrivacy}
          />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
