export default [
  {
    name: 'Home',
    hash: 'home',
  },
  {
    name: 'About',
    hash: 'about',
  },
  {
    name: 'Road Warrior',
    hash: 'games',
  },
  {
    name: 'Contact',
    hash: 'contact',
  },
];
