import { css } from '@emotion/core';

import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';

import { Colors } from './theme';

export default css`
  * {
    padding: 0;
    margin: 0;
  }

  body {
    background-color: ${Colors.black};
    font-family: open sans, sans-serif;
    font-size: 15px;
    line-height: 23px;
    color: ${Colors.gray};
    font-weight: 400;
    position: relative;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: oswald, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: ${Colors.blackTertiary};
  }

  h2 {
    font-family: oswald, sans-serif;
    font-size: 32px;
    color: ${Colors.white};
    margin: 0 0 10px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: -0.04em;
  }

  h3,
  h4 {
    margin: 0 0 10px;
    font-weight: 600;
    line-height: 1.7;
    color: ${Colors.white};
    letter-spacing: -0.01em;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 15px;
    margin-bottom: 15px;
  }

  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a,
  a:hover,
  .overlay,
  img,
  .form-control,
  .form-control:hover,
  button {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  a {
    color: ${Colors.orange};
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button {
    border: none;
    background: 0 0;
  }

  .default-btn {
    letter-spacing: 2px;
    background-color: ${Colors.orange};
    color: ${Colors.whiteSecondary};
    line-height: 45px;
    padding: 0 25px;
    border-radius: 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background-color: ${Colors.whiteSecondary};
      color: ${Colors.black};
    }
  }
`;
